<template>
  <b-modal id="comment-modal" centered @close="handleCancel">
    <validation-observer ref="commentFormRef">
      <form>
        <b-row>
          <b-col>
            <b-form-group class="select-form-group">
              <validation-provider #default="{ errors }" name="status">
                <b-form-select
                  class="h-100"
                  v-model="form.status_id"
                  :options="statusOptions"
                />
                <small class="text-danger"> {{ errors && errors[0] }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="form.status_id === 3 || form.status_id === 4" cols="3">
            <validation-provider #default="{ errors }" name="status">
              <b-form-group>
                <b-form-input
                  :placeholder="$t('min')"
                  v-model="form.min"
                  type="number"
                />
                <small class="text-danger"> {{ errors && errors[0] }} </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider #default="{ errors }" name="have excue">
              <b-form-group>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.has_excuse"
                  name="have excu"
                  value="1"
                  unchecked-value="0"
                >
                  {{ $t("haveExcuse") }}
                </b-form-checkbox>
                <small class="text-danger"> {{ errors && errors[0] }} </small>
              </b-form-group>
            </validation-provider> </b-col
          ><b-col cols="12">
            <validation-provider #default="{ errors }" name="status">
              <b-form-group>
                <b-form-textarea
                  no-resize
                  :placeholder="$t('comment')"
                  v-model="form.comment"
                />
                <small class="text-danger"> {{ errors && errors[0] }} </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </form>
    </validation-observer>
    <template #modal-footer>
      <b-row>
        <b-button vraiant="outline-primary" @click.prevent="handleCancel" class="mr-2">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click.prevent="handelSubmit">
          <b-spinner v-if="isLoading" />
          <span v-else>
            {{ $t("submit") }}
          </span>
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  BRow,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCol,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "CommentsModal",
  components: {
    BModal,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCol,
    BRow,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  },
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        status_id: null,
        has_excuse: 0,
        comment: null,
        min: null,
      },
      isLoading: false,
      statusOptions: [
        {
          value: 2,
          icon: require("@/assets/images/icons/done.svg"),
          text: this.$t("present"),
        },
        {
          value: 1,
          icon: require("@/assets/images/icons/trangel.svg"),
          text: this.$t("absence"),
        },
        {
          value: 3,
          icon: require("@/assets/images/icons/late.svg"),
          text: this.$t("cameLate"),
        },
        {
          value: 4,
          icon: require("@/assets/images/icons/leavesLate.svg"),
          text: this.$t("leaveEarly"),
        },
      ],
    };
  },
  watch: {
    data(newValue) {
      this.handelData(newValue);
    },
  },
  methods: {
    handelData(data) {
      const { student_id, status_id, id } = data;
      this.form.status_id = status_id;
    },
    handleCancel(){
      this.$bvModal.hide("comment-modal")
    },
    handelSubmit() {
      this.$refs.commentFormRef.validate().then((success) => {
        if (success) {
          this.handelSendingData();
        }
      });
    },
    async handelSendingData() {
      try {
        this.isLoading = true;
        const { student_id, id } = this.data;

        const formBody = this.$helpers.prepareFormData({
          ...this.form,
          id,
          student_id,
        });
        const res = await this.$http.post("admin/take-attendance", formBody);
        if (res.status === 200 || res.status === 201) {
          this.$bvModal.hide("comment-modal");
          this.$helpers.makeToast("success", this.res.message);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.select-form-group {
  margin-bottom: 0;
  div {
    span {
      select {
        height: 56px !important;
        border: 1px solid #5e5e5e;
        border-radius: 8px;
        margin-bottom: 8px;
      }
    }
  }
}
.spinner-border {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.close{
  z-index: 200;
}
</style>
