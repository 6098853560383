<template>
  <div class="files-component">
    <h3>
      {{ $t("showCourse.resources.filesHead") }}
      <b-spinner small v-if="isLoading" />
      <button v-else @click="$refs.ImportFileInput.$el.click()">
        <img :src="addIcon" alt="add-icon" />
      </button>
    </h3>
    <div v-for="file in filesData" class="file">
      <div class="info">
        <img
          :src="getIconType(file) ? getIconType(file) : icons.docs"
          alt="mimeTypeIcon"
        />
        <a :href="file.path" target="_blank"> {{ file.description }} </a>
      </div>
      <button
        :disabled="isLoading"
        class="control"
        @click="deleteFile(file.id)"
      >
        <feather-icon icon="Trash2Icon" outline="red" class="mr-50" />
      </button>
    </div>
    <b-form-file
      plain
      ref="ImportFileInput"
      hidden
      @change="handelNewFile"
      accept=".pdf,.xlsx"
    />
  </div>
</template>
<script>
import { BDropdown, BDropdownItem, BFormFile, BSpinner } from "bootstrap-vue";

export default {
  props: {
    filesData: {
      type: Array,
      required: true,
    },
  },

  name: "FilesComponent",
  components: { BDropdownItem, BDropdown, BFormFile, BSpinner },
  computed: {
    rtl() {
      return this.$i18n.locale === "ar";
    },
  },
  data() {
    return {
      isLoading: false,
      addIcon: require("@/assets/images/icons/addIcon.svg"),
      icons: {
        "application/pdf": require("@/assets/images/icons/pdf.png"),
        "application/xls": require("@/assets/images/icons/xls.png"),
        "application/xlsx": require("@/assets/images/icons/xls.png"),
        xls: require("@/assets/images/icons/xls.png"),
        xlsx: require("@/assets/images/icons/xls.png"),
        docs: require("@/assets/images/icons/docx.png"),
      },
    };
  },
  methods: {
    getIconType({ type, description }) {
      if (Object.keys(this.icons).includes(type)) {
        return this.icons[type];
      } else {
        const extension = description.split(".")[1];
        return this.icons[extension];
      }
    },
    async deleteFile(_id) {
      try {
        this.isLoading = true;
        const request = await this.$http.delete(`admin/resources/${_id}`);
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handelNewFile(event) {
      try {
        this.isLoading = true;
        const body = this.prepareData(event);
        const request = await this.$http.post("admin/resources", body);
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    prepareData(event) {
      const { files } = event.target;
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(`file[${i}]`, file);
        formData.append("description", file.name);
      });
      formData.append("course_id", this.$route.params.id);
      formData.append("type", "file");
      return formData;
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
