<template>
  <div class="attendance-section">
    <div class="tabs">
      <b-tabs pills>
        <b-tab>
          <template #title> {{ $t("details") }}</template>
          <attendance-details-table-component /> </b-tab
        ><b-tab>
          <template #title> {{ $t("summary") }}</template>
          <attendance-summary />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { BTabs, BTab, BCol, BRow } from "bootstrap-vue";
import AttendanceDetailsTableComponent from "@/components/classrooms/course/AttendanceComponents/AttendanceDetailsTableComponent/index.vue";
import AttendanceSummary from "@/components/classrooms/course/AttendanceComponents/AttendanceSummary/index.vue";
export default {
  name: "AttendanceComponent",
  components: {
    AttendanceDetailsTableComponent,
    AttendanceSummary,
    BTabs,
    BTab,
    BCol,
    BRow,
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
