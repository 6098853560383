<template>
  <loading v-if="isLoading" />
  <div v-else class="resources-component">
    <images-component
      :images-data="photosData"
      @update-data="getCourseResources"
    />
    <video-component
      @update-data="getCourseResources"
      :videos-data="videosData"
    />
    <files-component @update-data="getCourseResources" :files-data="files" />
    <description-component
      @update-data="getCourseResources"
      :description-data="description"
    />
    <links-component @update-data="getCourseResources" :links-data="links" />
  </div>
</template>
<script>
import DescriptionComponent from "@/components/classrooms/resourcesComponent/DescriptionComonent/index.vue";
import FilesComponent from "@/components/classrooms/resourcesComponent/FilesComponent/index.vue";
import ImagesComponent from "@/components/classrooms/resourcesComponent/ImagesComponent/index.vue";
import VideoComponent from "@/components/classrooms/resourcesComponent/VideosComponent/index.vue";
import LinksComponent from "@/components/classrooms/resourcesComponent/LInksComponent/index.vue";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "ResourcesComponent",
  components: {
    Loading,
    LinksComponent,
    VideoComponent,
    ImagesComponent,
    FilesComponent,
    DescriptionComponent,
  },
  data() {
    return {
      files: [],
      links: [],
      media: [],
      description: [],
      isLoading: true,
    };
  },
  computed: {
    videosData() {
      return this.media.filter((e) => e.type.split("/")[0] === "video");
    },
    photosData() {
      const imageMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/svg+xml",
      ];
      return this.media.filter((e) => imageMimeTypes.includes(e.type));
    },
  },
  created() {
    this.getCourseResources();
  },
  methods: {
    async getCourseResources() {
      try {
        this.isLoading = true;
        const request = await this.$http.get(
          `admin/resources/${this.$route.params.id}`
        );
        if (request.status === 200 || request.status === 201) {
          this.handelData(request.data.data);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handelData(requestData) {
      for (const item in requestData) {
        this[item] = requestData[item];
      }
    },
  },
};
</script>
