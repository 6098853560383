<template>
  <validation-observer ref="showBoardRef">
    <b-form>
      <b-modal :id="`bv-modal-show-board-${board.id}`" hide-footer centered>
        <!-- Start Modal Header -->
        <template #modal-header>
          <div class="mb-2 d-flex justify-content-between w-100 align-items-center">
            <b-card-text class="mb-0">
              {{ `${$t('g.show_course/boards/show-board')}-${board.title}` }}
            </b-card-text>
            <img class="cursor-pointer" :src="exitIcon" alt="Hide show course" @click="$bvModal.hide(`bv-modal-show-board-${board.id}`)">
          </div>
        </template>
        <!-- End Modal Header -->

        <!-- Start Board Title Input -->
        <b-form-group :label="$t('g.show_course/boards/title')" label-for="title" class="mb-2" label-size="lg">
          <validation-provider #default="{ errors }" name="title" rules="required">
            <b-form-input disabled :placeholder="$t('g.show_course/boards/title')" v-model="board.title" id="title" trim/>
            <small class="text-danger">{{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End Board Title Input -->

        <!-- Start Description Textarea -->
        <b-form-group :label="$t('g.show_course/boards/description')" label-for="description" class="mb-2"
                      label-size="lg"
        >
          <validation-provider #default="{ errors }" name="description" rules="required">
            <b-form-textarea disabled :placeholder="$t('g.show_course/boards/description')" v-model="board.description"
                             id="description"
                             rows="5" max-rows="10"
            />
            <small class="text-danger">{{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- End Description Textarea -->

        <!-- Start Styles Select -->
        <BoardStyles :isApproved="isApproved" :board="board"/>
        <!-- End Styles Select -->
      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  BButton,
  BCol,
  BForm,
  BFormTextarea,
  BRow,
  BFormGroup,
  BCardText,
  BFormInput, BCollapse
} from 'bootstrap-vue'
import BoardStyles from '@/components/classrooms/boards/showCourses/boardStyles/index.vue'

export default {
  name: 'ShowBoardModal',
  props: ['board', 'isApproved'],
  components: {
    BoardStyles,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BCollapse,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      exitIcon: require('@/assets/dev/exit_icon.svg'),
      plusIcon: require('@/assets/dev/image_input_icon2.svg'),
    }
  },
  created() {
    this.is_loading = false
  }
}
</script>

<style lang="scss" src="./_index.scss"/>
