<template>
  <b-modal
      :id="`bv-modal-reject-board-${board.id}`"
      :title="$t('g.reject_board')"
      hide-footer
      centered
  >
    <b-row class="justify-content-center">
      <b-col cols="12" class="text-center">
        <h3>{{ $t('g.are_you_sure_you_want_to_reject_this_board') }}</h3>
      </b-col>
      <b-col cols="12" class="text-center mt-2">
        <b-button
            variant="danger"
            class="mr-1"
            @click.stop="rejectCourse(board.id)"
        >
          {{ $t('g.reject') }}
        </b-button>
        <b-button
            variant="primary"
            @click.stop="$bvModal.hide(`bv-modal-reject-board-${board.id}`)"
        >{{ $t('g.cancel') }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BButton, BCol, BModal, BRow } from 'bootstrap-vue'

export default {
  name: 'RejectBoardModal',
  components: {
    BCol,
    BRow,
    BButton,
    BModal
  },
  props: {
    board: {
      type: Object,
      required: true,
    },
  },
  methods: {
    rejectCourse(boardId) {
      this.$http.delete(`/admin/boards/${boardId}`)
          .then(() => {
            this.$bvModal.hide(`bv-modal-reject-board-${boardId}`)
            this.$helpers.makeToast(
                'success',
                this.$t('g.rejected_successfully'),
                this.$t('g.board_rejected_successfully')
            )
            this.$emit('boardRejected')
          })
          .catch(error => {
            this.$helpers.handleError(error)
          })
    },
  },
}
</script>
