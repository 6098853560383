<template>
  <loading v-if="isLoading" />
  <div
    class="d-flex flex-column c-attendance"
    v-else-if="!isLoading && rows.length > 0"
  >
    <b-row
      class="d-flex align-self-end justify-content-between mb-1 mr-1 flex-wrap"
    >
      <b-col
        v-for="statusItem in status"
        :key="statusItem.id"
        class="d-flex align-items-center mr-1 mb-1"
        style="gap: 15px"
      >
        <img :src="statusItem.icon" :alt="statusItem.title" />
        <p class="m-0">{{ statusItem.title }}</p>
      </b-col>
    </b-row>
    <vue-good-table
      styleClass="vgt-table striped h-100 overflow-scroll attendance-table"
      :columns="columns"
      :rows="rows"
      :search-options="{ enabled: false, externalQuery: searchTerm }"
      :sort-options="{ enabled: false }"
    >
      <template slot="table-column" slot-scope="props">
        <span
          v-if="props.column.field === 'section'"
          class="d-flex justify-content-center p-0 align-items-center"
        >
          <span class="t-date">
            {{ props.column.label }}
          </span>
          <section-shortcuts-dropdown-component
            @update-table="updateData"
            :section-id="props.column.id"
          />
        </span>
        <span
          v-if="props.column.field === 'students'"
          class="d-flex justify-content-center align-items-center h-75"
        >
          {{ $t("students") }}
        </span> </template
      ><template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'section'"
          class="d-flex justify-content-center align-items-center h-100 w-94"
        >
          <status-component
            @fire-popup="firePopup"
            :data="{
              status: getStatus(props.column.id, props.row.user.id),
              attendanceId: getMyId(props.column.id, props.row.user.id),
              studentId: props.row.user.id,
            }"
            v-if="getStatus(props.column.id, props.row.user.id) !== false"
          />
          <span v-else> - </span>
        </span>
        <span v-if="props.column.field === 'students'">
          <students-info-component :user="props.row" />
        </span>
      </template>
    </vue-good-table>
    <comments-modal :data="modalData" />
  </div>
  <div class="d-flex justify-content-center w-100 no-data-section" v-else>
    <p>{{ $t("g.no_data_for_table") }}</p>
  </div>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { BDropdown, BDropdownItem, BRow, BCol } from "bootstrap-vue";
import SectionShortcutsDropdownComponent from "@/components/classrooms/course/AttendanceComponents/AttendanceDetailsTableComponent/SectionShortcutsDropdownComponent/index.vue";
import StudentsInfoComponent from "@/components/classrooms/course/AttendanceComponents/AttendanceDetailsTableComponent/StudentInfoComponent/index.vue";
import Loading from "@/components/loading/loading.vue";
import StatusComponent from "@/components/classrooms/course/AttendanceComponents/AttendanceDetailsTableComponent/StatusComponent/index.vue";
import CommentsModal from "@/components/classrooms/course/AttendanceComponents/AttendanceDetailsTableComponent/CommentModal/index.vue";

export default {
  name: "AttendanceDetailsTableComponent",
  components: {
    CommentsModal,
    StatusComponent,
    Loading,
    StudentsInfoComponent,
    SectionShortcutsDropdownComponent,
    BDropdownItem,
    BDropdown,
    VueGoodTable,
    BRow,
    BCol,
  },
  created() {
    this.getSectionData();
  },
  data() {
    return {
      modalData: {},
      rows: [],
      searchTerm: "",
      wholeData: [],
      students_id: [],
      isLoading: false,
      columns: [
        {
          label: "students",
          field: "students",
        },
      ],
      status: [
        {
          id: 2,
          icon: require("@/assets/images/icons/done.svg"),
          label: "present",
          title: this.$t("present"),
        },
        {
          id: 1,
          icon: require("@/assets/images/icons/trangel.svg"),
          label: "absence",
          title: this.$t("absence"),
        },
        {
          id: 3,
          icon: require("@/assets/images/icons/late.svg"),
          label: "come late",
          title: this.$t("cameLate"),
        },
        {
          id: 4,
          icon: require("@/assets/images/icons/leavesLate.svg"),
          label: "leave early",
          title: this.$t("leaveEarly"),
        },
      ],
    };
  },
  methods: {
    async getSectionData(loading = true) {
      try {
        if (loading) {
          this.isLoading = true;
        }
        const res = await this.$http.get(
          `admin/index/${this.$route.params.id}`
        );
        if (res.status === 200 || res.status === 201) {
          this.handelData(res.data.data);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        if (loading) {
          this.isLoading = false;
        }
      }
    },
    handelData(data) {
      this.columns.length = 1;
      this.rows = [];
      this.students_id = [];
      this.wholeData = [];
      data.forEach((section) => {
        const { id, start_date, name, attendance } = section;
        this.columns.push({
          label: this.$helpers.formatDateToDayMonth(
            start_date,
            this.$i18n.locale === "ar"
          ),
          id,
          field: "section",
        });
        attendance.forEach(({ student, status_id }) => {
          if (!this.students_id.some((e) => e === student.user.id)) {
            this.rows.push(student);
            this.students_id.push(student.user.id);
          }
        });
        this.wholeData.push(section);
      });
    },
    getStatus(sectionId, userId) {
      const wantedSection = this.wholeData.filter(
        (section) => section.id === sectionId
      )[0];
      const wantedAttendanceObj = wantedSection.attendance?.filter(
        (attendanceItem) => attendanceItem.student.user.id === userId
      );
      if (
        wantedAttendanceObj.length > 0 &&
        wantedAttendanceObj[0].hasOwnProperty("status_id")
      ) {
        return wantedAttendanceObj[0].status_id;
      }
      return false;
    },
    firePopup(data) {
      this.modalData = data;
      this.$bvModal.show("comment-modal");
    },
    getMyId(sectionId, userId) {
      const section = this.wholeData.filter(
        (section) => section.id === sectionId
      )[0];

      const user_id = section.attendance?.filter(
        (ele) => ele.student.user.id === userId
      )[0]?.id;
      return user_id;
    },
    updateData() {
      this.getSectionData();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@/assets/scss/style";
.custom {
  li {
    a {
      border-radius: 16px !important;
    }
    border-radius: 16px !important;
  }
  ul {
    border-radius: 16px;
  }
}
th {
  min-width: 200px !important;
}
vgt-left-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-attendance {
  .vgt-table thead th {
    padding: 0.75em 0;
    min-width: 150px !important;
    .t-date {
      color: #6e6b7b;
      vertical-align: bottom;
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
  }
  .vgt-wrap {
    border: 2px solid $primary-color !important;
    border-radius: 25px !important;
    overflow: auto;
  }
}
.no-data-section {
  p {
    font-weight: 500;
    font-size: 21px;
    color: $primary-color;
  }
}
</style>
