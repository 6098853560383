<template>
  <b-dropdown
    class="custom custom-shortcut"
    variant="link"
    toggle-class="text-decoration-none toggle custom"
    no-caret
  >
    <template v-slot:button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="text-body align-middle"
      />
    </template>

    <b-dropdown-item @click="updateData('update_all')">
      <feather-icon icon="EditIcon" class="mr-50 text-success" />
      <span class="text-success">{{ $t("allIsPresent") }}</span>
    </b-dropdown-item>
    <b-dropdown-item @click="updateData('delete_all')">
      <feather-icon icon="Trash2Icon" class="mr-50 text-danger" />
      <span class="text-danger">{{ $t("deleteAll") }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  name: "SectionShortcutsDropdownComponent",
  props: {
    sectionId: {
      required: true,
    },
  },
  components: {
    BDropdownItem,
    BDropdown,
  },
  methods: {
    async updateData(type) {
      try {
        const bodyData = this.$helpers.prepareFormData({
          section_id: this.sectionId,
          type,
        });
        const res = await this.$http.post(
          "admin/update-all-attendance",
          bodyData
        );
        if (res.status === 200 || res.status === 201) {
          this.$helpers.makeToast("success", res.data.message);
          this.$emit("update-table", false);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
  },
};
</script>

<style lang="scss">
.custom-shortcut {
  .btn {
    padding: 0.786rem 0;
  }
}
</style>
