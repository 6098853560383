<template>
  <div class="card-board">
    <b-col class="p-0" @click="navigateToBoard(board.id)">
      <div class="board__body">
        <img
          v-if="styleExists"
          :src="board.style.background_image[0].path"
          :alt="board.title"
          class="board__image"
        />
        <img
          v-else
          src="@/assets/images/pages/Rectangle 846.png"
          :alt="board.title"
          class="board__image"
        />
        <div class="requested__board__header" v-if="!isApproved">
          <h4 class="board__name">{{ board.title }}</h4>
        </div>

        <div
          class="board__content"
          :class="{ 'board__content--not-approved': !isApproved }"
        >
          <h4 class="board__name" v-if="isApproved">{{ board.title }}</h4>

          <b-row
            class="mx-0"
            v-if="!isApproved && (permission.update || permission.destroy)"
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="light"
              v-if="permission.update"
              @click.stop="approveBoard(board.id)"
            >
              {{ $t("g.show_course/boards/approve") }}
            </b-button>
            <b-button
              class="board__button--delete"
              size="sm"
              v-if="permission.destroy"
              variant="outline-light"
              @click.stop="$bvModal.show(`bv-modal-reject-board-${board.id}`)"
            >
              {{ $t("g.cancel") }}
            </b-button>

            <ShowBoardModal
              :board="board"
              :isApproved="isApproved"
              @boardApproved="boardApproved"
            />

            <RejectBoardModal :board="board" @boardRejected="boardApproved" />
          </b-row>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import { BButton, BCol, BRow } from "bootstrap-vue";
import ShowBoardModal from "@/components/classrooms/boards/showCourses/showBoardModal/index.vue";
import RejectBoardModal from "@/components/classrooms/boards/showCourses/rejectBoardModal/index.vue";

export default {
  props: ["board", "isApproved", "permission"],
  components: {
    RejectBoardModal,
    ShowBoardModal,
    BCol,
    BButton,
    BRow,
  },
  data() {
    return {
      styleExists: false,
    };
  },
  methods: {
    approveBoard(boardId) {
      let formData = new FormData();
      formData.append("is_approved", 1);

      this.$http
        .post(`admin/boards/${boardId}?_method=PUT`, formData)
        .then(() => {
          this.$helpers.makeToast(
            "success",
            this.$t("g.show_course/boards/approved_successfully"),
            this.$t("g.show_course/boards/board_approved_successfully")
          );
          this.$emit("boardApproved");
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    navigateToBoard(boardId) {
      this.isApproved && this.permission.show
        ? this.$router.push({ name: "ShowBoard", params: { id: boardId } })
        : this.$bvModal.show(`bv-modal-show-board-${boardId}`);
    },
    boardApproved() {
      this.$emit("boardApproved");
    },
  },
  created() {
    this.styleExists =
      ![undefined, null].includes(this.board.style) &&
      Object.keys(this.board.style).length;
  },
};
</script>

<style scoped lang="scss" src="./_index.scss" />
