<template>
  <div class="status-component">
    <button @click.prevent="incrementStatus" class="main-btn">
      <img v-if="currentStatus !== 0" :src="currentIcon" />
      <span v-else>-</span>
    </button>
    <button @click.prevent="firePopUp" class="actions">
      <!-- <feather-icon icon="Edit2Icon" /> -->
      <img :src="penIcon" alt="">
    </button>
  </div>
</template>
<script>
export default {
  name: "StatusComponent",
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      penIcon: require("@/assets/images/icons/yahya.svg"),
      currentStatus: 0,
      id: null,
      status: [
        {
          id: 2,
          icon: require("@/assets/images/icons/done.svg"),
          label: "present",
        },
        {
          id: 0,
          icon: require("@/assets/images/icons/done.svg"),
          label: "dassh",
        },
        {
          id: 1,
          icon: require("@/assets/images/icons/trangel.svg"),
          label: "absence",
        },
        {
          id: 3,
          icon: require("@/assets/images/icons/late.svg"),
          label: "come late",
        },
        {
          id: 4,
          icon: require("@/assets/images/icons/leavesLate.svg"),
          label: "leave early",
        },
      ],
    };
  },
  computed: {
    currentIcon() {
      return this.status.filter((el) => el.id === this.currentStatus)[0]?.icon;
    },
  },
  created() {
    this.id = this.getRandomNumber(1, 1000000);
    this.currentStatus = this.data.status === null ? 0 : this.data.status;
  },
  methods: {
    incrementStatus() {
      if (this.currentStatus + 1 > 4) {
        this.currentStatus = 1;
      } else {
        this.currentStatus++;
      }
      this.$helpers.debounce(this.submitStatus(), 500);
    },
    async submitStatus() {
      try {
        const { studentId, attendanceId } = this.data;
        const bodyData = this.$helpers.prepareFormData({
          id: attendanceId,
          student_id: studentId,
          status_id: this.currentStatus,
        });
        const res = await this.$http.post("admin/take-attendance", bodyData);
        if (res.status === 200 || res.status === 201) {
          this.$helpers.makeToast("success", res.data.message);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    firePopUp() {
      const { studentId, attendanceId } = this.data;

      this.$emit("fire-popup", {
        student_id: studentId,
        status_id: this.currentStatus,
        id: attendanceId,
      });
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>
<style lang="scss">
.status-component {
  position: relative;
  height: 54px;
  width: 92px;
}
.main-btn {
  all: unset;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 14;
  img {
    height: 24px;
    width: 24px;
  }
}
.actions {
  all: unset;
  position: absolute;
  left: 5px;
  z-index: 4;
  top: 25px;
}
</style>
