<template>
  <div
    class="show-course-boards"
    :class="{ 'mt-0': isApproved && notApprovedBoardsLength > 0 }"
  >
    <div class="course-boards">
      <div class="course-boards__header">
        <div class="boards-title">
          <h4 class="title">
            {{
              isApproved
                ? $t("g.classroom.boards")
                : $t("g.classroom.requested_boards")
            }}
          </h4>
          <span class="icon">
            <feather-icon size="16" icon="AlertCircleIcon" color="#135991" />
          </span>
        </div>
        <div class="boards-all-link" v-if="isApproved">
          <b-link
            class="boards-link"
            :to="
              permission.index
                ? {
                    name: 'BoardsList',
                    params: { id: course_id },
                  }
                : null
            "
          >
            <span>{{ $t("g.classroom.see_all_boards") }}</span>
            <span>
              <feather-icon size="16" icon="ChevronRightIcon" color="#135991" />
            </span>
          </b-link>
        </div>
      </div>
      <div class="borders">
        <div class="borders__container">
          <div
            v-if="isApproved && permission.store"
            class="add-board-card mb-2"
            @click="$bvModal.show('bv-modal-add-board')"
          >
            <div class="content-container">
              <div class="add-circle">
                <img :src="require('@/assets/dev/plus.svg')" alt="" />
              </div>
              <h3>{{ $t("g.show_course/boards/add-board") }}</h3>
            </div>
          </div>

          <AddBoardModal v-if="isApproved" @addBoard="addBoard" />

          <card
            v-for="board in boards"
            :board="board"
            :key="board.id"
            :isApproved="isApproved"
            :permission="permission"
            @boardApproved="addBoard"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
import Card from "./layout/card.vue";
import AddBoardModal from "@/components/classrooms/boards/showCourses/addBoardModal/index.vue";

export default {
  components: {
    AddBoardModal,
    BLink,
    Card,
  },
  props: [
    "boards",
    "course_id",
    "isApproved",
    "notApprovedBoardsLength",
    "permission",
  ],
  methods: {
    addBoard() {
      this.$emit("addBoard");
    },
  },
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
