<template>
  <div class="description-modal">
    <b-modal
      id="description-control-modal"
      :title="modalTitle"
      centered
      size="lg"
    >
      <validation-observer ref="descriptionModalForm">
        <b-form>
          <validation-provider
            #default="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-group class="mt-2">
              <quill-editor ref="myQuillEditor" v-model="form.description" />
            </b-form-group>
            <small class="text-danger"> {{ errors && errors[0] }} </small>
          </validation-provider>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button :disabled="isLoading" @click.prevent="handleCancel" variant="outline-primary">
          {{ $t("cancel") }}
        </b-button>
        <b-button
          :disabled="isLoading"
          @click.prevent="handelValidation"
          variant="primary"
        >
          {{ isEdit ? $t("edit") : $t("submit") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { BModal, BForm, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "DescriptionModal",
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    BButton,
    ValidationProvider,
    BModal,
    quillEditor,
    ValidationObserver,
    required,
    BFormGroup,
    BFormInput,
    BForm,
  },
  computed: {
    modalTitle() {
      return this.isEdit
        ? this.$t("showCourse.description.modal.titleEdit")
        : this.$t("showCourse.description.modal.titleAdd");
    },
  },
  data() {
    return {
      form: {
        description: null,
      },
      isLoading: false,
    };
  },
  methods: {
    handelValidation() {
      this.$refs.descriptionModalForm.validate().then((success) => {
        if (success) {
          if (this.isEdit) {
            this.handelEdit();
          } else {
            this.handelSubmit();
          }
        }
      });
    },
    handleCancel(){
      this.$bvModal.hide("description-control-modal");
    },
    async handelSubmit() {
      try {
        this.isLoading = true;
        const body = this.$helpers.prepareFormData({
          ...this.form,
          type: "description",
          course_id: this.$route.params.id,
        });
        const response = await this.$http.post("admin/resources", body);
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast("success", response.data.message);
          this.$bvModal.hide("description-control-modal");
          this.$emit("update-description");
          this.resetData();
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handelEdit() {
      try {
        this.isLoading = true;
        const body = this.$helpers.prepareFormData({
          ...this.form,
          course_id: this.$route.params.id,
        });
        const response = await this.$http.post(
          `admin/resources/${this.data.id}?_method=put  `,
          body
        );
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast("success", response.data.message);
          this.$bvModal.hide("description-control-modal");
          this.$emit("update-description");
          this.resetData();
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    resetData() {
      for (const item in this.form) {
        this.form[item] = null;
      }
    },
  },
  watch: {
    isEdit(newValue) {
      if (newValue) {
        for (const item in this.form) {
          this.form[item] = this.data[item];
        }
      } else {
        this.resetData();
      }
    },
  },
};
</script>
