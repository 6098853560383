<template>
  <b-modal id="video-preview-modal" hide-footer centered size="lg">
    <video height="400" controls="controls">
      <source :src="path" :type="type" height="400" />
    </video>
  </b-modal>
</template>
<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "VideoPreviewComponent",
  components: { BModal },
  props: {
    path: {
      required: true,
    },
    type: {
      required: true,
    },
  },
};
</script>
<style>
video {
  width: 100% !important;
}
</style>
