<template>
  <b-form-group :label="$t('g.show_course/boards/styles')" label-for="styles" class="mb-2" label-size="lg">
    <validation-provider #default="{ errors }" name="style" :rules="isApproved ? 'required' : ''">
      <div class="styles-container">
        <div class="styles-content">

          <!-- Default Style -->
          <b-row :class="{ 'd-none': is_collapse_visible }">
            <b-col cols="12" class="available-styles">
              <span class="styles-color-cube" @click="isApproved ? selectStyle(null) : ''"
                    :style="{ backgroundImage: `url(${noStyleIcon})`, marginLeft: '8px' }"
                    :class="{'cursor-pointer': isApproved, 'selected-style': selectedStyle === null}"
              />
              <span v-for="style in styles" :key="style.id" :id="`style_${style.id}`"
                    @click="isApproved ? selectStyle(style.id) : ''" class="styles-color-cube opacity-30"
                    :class="{ 'selected-style': selectedStyle === style.id, 'cursor-pointer': isApproved }"
                    :style="{ backgroundColor: style.primary_color }"
              />
            </b-col>
          </b-row>
          <!-- End Default Style -->

          <!-- Show All Styles -->
          <b-collapse id="show-all-styles" style="margin-left: 4px" :visible="selectedStyle !== null && !isApproved">
            <span class="styles-color-cube" @click="isApproved ? selectStyle(null) : ''"
                  :style="{ backgroundImage: `url(${noStyleIcon})`, marginLeft: '4px' }"
                  :class="{'cursor-pointer': isApproved, 'selected-style': selectedStyle === null}"
            />
            <span v-for="style in styles" :key="style.id" :id="`style_${style.id}`"
                  @click="isApproved ? selectStyle(style.id) : ''" class="styles-color-cube opacity-30"
                  :class="{ 'selected-style': selectedStyle === style.id, 'cursor-pointer': isApproved }"
                  :style="{ backgroundColor: style.primary_color }"
            />
          </b-collapse>
          <!-- End Show All Styles -->

          <!-- Show All Styles Button -->
          <b-row>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button class="show-all-styles-btn" @click="showAllStyles">
                {{
                  is_collapse_visible ? $t('g.show_course/boards/hide-all-styles') :
                      $t('g.show_course/boards/show-all-styles')
                }}
                <feather-icon :icon="is_collapse_visible ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="12"/>
              </b-button>
            </b-col>
          </b-row>
          <!-- End Show All Styles Button -->

        </div>
      </div>
      <small class="text-danger">{{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BButton, BCol, BCollapse, BFormGroup, BRow } from 'bootstrap-vue'

export default {
  name: 'BoardStyles',
  props: {
    isApproved: {
      type: Boolean,
      default: false
    },
    board: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BCollapse,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    ValidationProvider
  },
  data() {
    return {
      styles: [],
      selectedStyle: null,
      is_collapse_visible: false,
      noStyleIcon: require('@/assets/images/icons/no-style-cube.png'),
    }
  },
  methods: {
    showAllStyles() {
      this.$root.$emit('bv::toggle::collapse', 'show-all-styles')
      this.is_collapse_visible = !this.is_collapse_visible
    },
    selectStyle(style) {
      this.selectedStyle = style
      this.$emit('selectStyle', this.selectedStyle)
    },
    getBoardStyles() {
      this.$http.get('/admin/board-styles')
          .then((response) => {
            this.styles = response.data.data
          })
          .catch((error) => {
            this.makeToast('danger', 'g.error', 'g.error')
          })
    },
  },
  created() {
    this.getBoardStyles()
    if (!this.isApproved && ![undefined, null].includes(this.board)) {
      this.selectedStyle = Object.keys(this.board.style).length ? this.board.style.id : null
      this.is_collapse_visible = true
    }
  },
}
</script>

<style src="./_boardStyles.scss" lang="scss"/>
