<template>
  <div class="links-component">
    <h3>
      {{ $t("showCourse.resources.linksHead") }}
      <button @click="openModal({ isEdit: false, data: {} })">
        <img :src="addIcon" alt="add-icon" />
      </button>
    </h3>
    <div class="links">
      <div v-for="link in linksData" :key="link.id" class="link">
        <a target="_blank" :href="link.path">
          <span> {{ link.description }}</span>
        </a>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          :dropleft="rtl"
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item @click="openModal({ isEdit: true, data: link })">
            <feather-icon icon="EditIcon" class="mr-50" />
            <span>{{ $t("g.edit") }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteLink(link.id)">
            <feather-icon icon="Trash2Icon" class="mr-50" />
            <span>{{ $t("g.delete") }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <links-control-modal
      @update-links="$emit('update-data')"
      :data="modalData"
      :is-edit="modalEdit"
    />
  </div>
</template>
<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import LinksControlModal from "@/components/classrooms/resourcesComponent/Modals/LinksModal/index.vue";

export default {
  name: "LinksComponent",
  components: { LinksControlModal, BDropdownItem, BDropdown },
  props: {
    linksData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addIcon: require("@/assets/images/icons/addIcon.svg"),
      modalEdit: false,
      modalData: {},
    };
  },
  computed: {
    rtl() {
      return this.$i18n.locale === "ar";
    },
  },
  methods: {
    async deleteLink(_id) {
      try {
        const request = await this.$http.delete(`admin/resources/${_id}`);
        if (request.status === 200 || request.status === 201) {
          this.$helpers.makeToast("success", request.data.message);
          this.$emit("update-data");
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },

    openModal({ isEdit, data: { id, description, path } }) {
      this.modalEdit = isEdit;
      if (isEdit) {
        this.modalData = { id, description, link: path };
      }
      this.$bvModal.show("links-control-modal");
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
