<template>
  <div class="student-component">
    <b-avatar size="6rem" :to="next" :src="myImage" />
    <h4>{{ student.name }}</h4>
    <button
      v-if="!addition"
      class="delete-ico"
      @click="deAttachUserFromCourse(student.enrollmentId)"
    >
      <feather-icon icon="Trash2Icon" />
    </button>
  </div>
</template>
<script>
import { BAvatar, BButton } from "bootstrap-vue";
export default {
  name: "StudentComponent",
  props: {
    student: {
      type: Object,
      required: true,
    },
    addition: {
      type: Boolean,
    },
  },
  data() {
    return {
      addIcon: require("@/assets/images/icons/addIconPlus.svg"),
    };
  },
  computed: {
    next() {
      if (this.addition) {
        return {
          name: "CourseAddStudents",
          params: { id: this.$route.params.id },
        };
      } else {
        return { name: "showStudent", params: { id: this.student.id } };
      }
    },
    myImage() {
      if (this.addition) {
        return this.addIcon;
      } else if (this.student.image) {
        return this.student.image;
      } else {
        return this.$helpers.uiAvatar(this.student.name);
      }
    },
  },
  components: {
    BAvatar,
    BButton,
  },
  methods: {
    async deAttachUserFromCourse(_id) {
      try {
        const courseId = this.$route.params.id;
        const request = await this.$http.delete(
          `admin/enrollments/${courseId}/${_id}`
        );
        if ([200, 201].includes(request.status)) {
          this.$emit("delete-student", _id);
          this.$helpers.makeToast(
            "success",
            this.$t("showCourse.alerts.studentDeletedFromCourseSuccessfully")
          );
        }
      } catch (err) {
        console.log(err);
        this.$helpers.handleError(err);
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
