<template>
  <div class="std-card">
    <b-avatar
      :src="
        $helpers.filterImages(
          $helpers.uiAvatar(user.user.name),
          'avatar',
          user.user.media
        )
      "
    />
    <p>
      {{ user.user.name }}
    </p>
    <b-dropdown variant="link" toggle-class="text-decoration-none">
      <template #button-content>
        <img
          class="small-img ml-100"
          :src="downwardArrow"
          alt="downward-arrow"
        />
      </template>
      <b-dropdown-item v-for="itemOfStatus in status" :key="itemOfStatus.id">
        <img class="small-img" :src="itemOfStatus.icon" />
        <span> {{ user.attendance_summary[itemOfStatus.label] }} </span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { BDropdown, BAvatar, BDropdownItem } from "bootstrap-vue";

export default {
  name: "StudentsInfoComponent",
  components: { BDropdown, BAvatar, BDropdownItem },
  props: { user: { type: Object } },
  data() {
    return {
      downwardArrow: require("@/assets/images/bottomArrow.svg"),
      status: [
        {
          id: 0,
          icon: require("@/assets/images/icons/done.svg"),
          label: "present_count",
        },
        {
          id: 1,
          icon: require("@/assets/images/icons/trangel.svg"),
          label: "absent_count",
        },
        {
          id: 2,
          icon: require("@/assets/images/icons/late.svg"),
          label: "come_late_count",
        },
        {
          id: 3,
          icon: require("@/assets/images/icons/leavesLate.svg"),
          label: "come_early_count",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
