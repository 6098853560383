<template>
  <div class="links-modal">
    <b-modal id="links-control-modal" :title="modalTitle" centered>
      <validation-observer ref="linksModalForm">
        <b-form>
          <b-form-group :label="$t('link')" class="mt-2">
            <validation-provider
              #default="{ errors }"
              :name="$t('link')"
              rules="required|url"
            >
              <b-form-input v-model="form.link" />
              <small class="text-danger"> {{ errors && errors[0] }} </small>
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('description')" class="mt-2">
            <validation-provider
              #default="{ errors }"
              :name="$t('description')"
              rules="required"
            >
              <b-form-input v-model="form.description" />
              <small class="text-danger"> {{ errors && errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button :disabled="isLoading" @click.prevent="handleCancel" variant="outline-primary">
          {{ $t("cancel") }}
        </b-button>
        <b-button
          :disabled="isLoading"
          @click.prevent="handelValidation"
          variant="primary"
        >
          {{ isEdit ? $t("edit") : $t("submit") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { BForm, BFormGroup, BFormInput, BModal, BButton } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "LinksControlModal",
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BButton,
  },

  computed: {
    modalTitle() {
      return this.isEdit
        ? this.$t("showCourse.links.modal.titleEdit")
        : this.$t("showCourse.links.modal.titleAdd");
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        link: null,
        description: null,
      },
    };
  },
  methods: {
    handelValidation() {
      this.$refs.linksModalForm.validate().then((success) => {
        if (success) {
          if (!this.isEdit) {
            this.handelSubmit();
          } else {
            this.handelEdit();
          }
        }
      });
    },
    handleCancel(){
      this.$bvModal.hide("links-control-modal");
    },
    async handelSubmit() {
      try {
        this.isLoading = true;
        const body = this.$helpers.prepareFormData({
          ...this.form,
          type: "link",
          course_id: this.$route.params.id,
        });
        const response = await this.$http.post("admin/resources", body);
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast("success", response.data.message);
          this.$bvModal.hide("links-control-modal");
          this.$emit("update-links");
          this.resetData();
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handelEdit() {
      try {
        this.isLoading = true;
        const body = this.$helpers.prepareFormData({
          ...this.form,
          course_id: this.$route.params.id,
        });
        const response = await this.$http.post(
          `admin/resources/${this.data.id}?_method=put  `,
          body
        );
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast("success", response.data.message);
          this.$bvModal.hide("links-control-modal");
          this.$emit("update-links");
          this.resetData();
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    resetData() {
      for (const item in this.form) {
        this.form[item] = null;
      }
    },
  },
  watch: {
    isEdit(newValue) {
      if (newValue) {
        for (const item in this.form) {
          this.form[item] = this.data[item];
        }
      } else {
        this.resetData();
      }
    },
  },
};
</script>
