var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-lesson-modal cursor-pointer",on:{"click":function($event){return _vm.$bvModal.show('bv-modal-add-week')}}},[_c('div',{staticClass:"add-circle"},[_c('img',{attrs:{"src":require('@/assets/dev/plus.svg'),"alt":""}})]),_c('h3',[_vm._v(_vm._s(_vm.$t("g.show_course/weeks/add_week")))]),_c('validation-observer',{ref:"addLessonRef"},[_c('b-form',[_c('b-modal',{attrs:{"id":"bv-modal-add-week","centered":"","ok-title-html":_vm.$t('g.show_course/weeks/send'),"ok-title":_vm.$t('g.submit'),"cancel-title":_vm.$t('cancel')},on:{"ok":_vm.validation},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"mb-2 d-flex justify-content-between w-100 align-items-center"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("g.show_course/weeks/add_week"))+" ")]),_c('img',{staticClass:"cursor-pointer",attrs:{"src":_vm.exitIcon,"alt":"Hide add week"},on:{"click":function($event){return _vm.$bvModal.hide('bv-modal-add-week')}}})],1),_c('span',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.$t("g.please_fill_in_the_required_data"))+" ")])]},proxy:true}])},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"name","label":_vm.$t('g.show_cours/weeks/week_name'),"label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('g.show_cours/weeks/week_name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.show_course/weeks/description'),"label-for":"description","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('g.show_course/weeks/description'),"id":"description","rows":"5","max-rows":"10"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"start date"}},[_c('validation-provider',{attrs:{"name":"start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"placeholder":_vm.$t('g.start_date')},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"end date"}},[_c('validation-provider',{attrs:{"name":"end date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"placeholder":_vm.$t('g.end_date')},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),(_vm.dateError)?_c('b-col',{staticClass:"date-error",attrs:{"cols":"12"}},[_c('p',[_vm._v(_vm._s(_vm.$t("g.alert.the_start_date_must_be_before_end_date")))])]):_vm._e()],1),(!_vm.selectedimage)?_c('div',{staticClass:"image-input-area"},[_c('b-form-group',{attrs:{"label-for":"course image"}},[_c('validation-provider',{attrs:{"name":"week image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{ref:"courseImage",staticClass:"image-input",attrs:{"accept":"image/*"},on:{"change":_vm.handleFileChange},model:{value:(_vm.selectedimage),callback:function ($$v) {_vm.selectedimage=$$v},expression:"selectedimage"}}),_c('div',{staticClass:"image-icon"},[_c('img',{attrs:{"src":_vm.imageInputIcon,"alt":"Add week image"}}),_c('div',{staticClass:"icon-circle "},[_c('img',{attrs:{"src":_vm.plusIcon,"alt":"plus icon"}})])]),_c('p',{staticClass:"image-text mb-0"},[_vm._v(_vm._s(_vm.$t("g.upload_photo")))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,493343780)})],1)],1):_vm._e(),(_vm.selectedimage)?_c('div',{staticClass:"mt-1 d-flex w-100 align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.imageIcon,"width":"22","height":"22","alt":""}}),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.selectedimage.name))])]),_c('img',{attrs:{"src":_vm.editIcon,"alt":""},on:{"click":_vm.editImage}})]):_vm._e(),_c('overlay-component',{attrs:{"isLoading":_vm.is_loading}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }